import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: "",
  success: "",
  isLoading: true,
  data: {
    ready: false,
    currencies: [],
    orderStatus: [],
    paymentConditions: [],
    deliveryLocations: [],
    shippingConditions: [],
    clients: [],
    paymentMethods: [],
    countries: [],
    services: [],
    productTypes: [],
    productCategories: [],
    productStyles: [],
    productCharacteristics: [],
    productTechnologies: [],
    productMeasures: [],
    productColors: [],
    productSizes: [],
    productWidths: [],
    productTaxRates: [],
    suppliers: [],
    positions: [],
    contractTypes: [],
    modulationTypes: [],
    employeeCategory: [],
    employeeStatus: [],
    employeeCostCenter: [],
    employeePosition: [],
    contactTypes: [],
    bannersType: [],
    receivingServers: [],
    users: [],
    processes: [],
    rawMaterialFamily: [],
    suppliersRawMaterial: [],
    productMeasuresUnit: [],
    arrivalTypes: [],
    departureTypes: [],
    attachmentTypes: [],
    returnReasons: [],
    warehouseTypes: [],
    backendVersion: null,
    languagesAvailable: [],
  },
};

const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    infoSuccess(state, action) {
      state.data = action.payload;
      state.isLoading = false;
    },
    infoError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { infoSuccess, infoError } = infoSlice.actions;

export default infoSlice.reducer;
