import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useLanguageRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const updateLanguageInUrl = useCallback(() => {
    const currentLanguage = JSON.parse(localStorage.getItem("I18N_LANGUAGE"));

    if (location.pathname.includes("/iframe")) {
      const currentParams = new URLSearchParams(location.search);

      if (!currentParams.has("languageId") && currentLanguage?.id) {
        const newParams = new URLSearchParams();
        newParams.set("languageId", currentLanguage.id.toString());

        currentParams.forEach((value, key) => {
          if (key !== "languageId") {
            newParams.set(key, value);
          }
        });

        const newUrl = `${location.pathname}?${newParams.toString()}${location.hash}`;
        navigate(newUrl, { replace: true });
      }
    }
  }, [location, navigate]);


  return updateLanguageInUrl;
};

