import React, { useEffect } from "react";
import "./assets/scss/themes.scss";
import Route from "./Routes";
import { useDispatch } from "react-redux";
import { getProfile } from "./slices/auth/profile/thunk";
import { getInfo } from "./slices/info/thunk";
import { useLanguageRouter } from "./Components/Hooks/useLanguageRouter";
function App() {
  const dispatch = useDispatch();
  const authToken = localStorage.getItem("authToken");
  const updateLanguageInUrl = useLanguageRouter();

  useEffect(() => {
    if (authToken) {
      dispatch(getProfile());
      dispatch(getInfo());
    }
  }, [authToken, dispatch]);

  useEffect(() => {
    updateLanguageInUrl();
  }, [updateLanguageInUrl]);

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;

