
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationPT from "./locales/pt.json";
import translationEN from "./locales/en.json";

const resources = {
  pt: {
    translation: translationPT,
  },
  en: {
    translation: translationEN,
  },
};


const storedLanguage = localStorage.getItem("I18N_LANGUAGE");
if (!storedLanguage) {
  localStorage.setItem("I18N_LANGUAGE", JSON.stringify({ abrev: "en", id: 1 }));
}

const currentLanguage = JSON.parse(localStorage.getItem("I18N_LANGUAGE"));

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: currentLanguage?.abrev || "en",
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;